<i18n locale="th" lang="yaml" >
page.title : "สร้าง Ticket จากใบงาน {service}"
page.description : "สร้าง Ticket เพื่อแจ้งปัญหา Drone ที่เกิดขึ้น จากใบงานเลขที่ {service}"

ticket_create.service.link : "ใบงาน {service}"
ticket_create.comment.note : "เปิดจากใบงาน"
ticket_create.confirm.title : "ยืนยันการเปิด Ticket"
ticket_create.confirm.message : "คุณต้องการยืนยันการเปิด Ticket ตัวนี้ ?"
ticket_create.success : "เปิด Ticket เรียบร้อยด้วย เลข Ticket {ticket}"
ticket_create.submit.label : "เปิด Ticket"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ service: service.service_no })">
			<div slot="description">
				{{$t('page.description',{ service: service.service_no })}}
				<div class="service-link-block">
				<my-router-link name="service/view" :param-value="service.id" new-window>
					{{$t('ticket_create.service.link',{ service: service.service_no })}}
				</my-router-link>
				</div>
			</div>
		</my-page-header>

		<div class="mylayout-right-left responsive">
			<div class="mylayout-left">
				<a-card :bordered="false">
					<TicketForm
						ref="formRef"
						:loading="loading"
						mode="create-with-drone"
						@submit="handleSubmit">
						<template v-slot:submitSlot="{ formLoading }">
							<a-button
								:disabled="formLoading"
								icon="left"
								size="large"
								:style="{ 'margin-right': '4px' }"
								@click="goBack">
								{{$t('common.back')}}
							</a-button>
							<a-button
								:loading="formLoading"
								type="info"
								html-type="submit"
								class="btn-submit"
								icon="edit"
								size="large">
								{{$t('ticket_create.submit.label')}}
							</a-button>
						</template>
					</TicketForm>
				</a-card>
			</div>
			<div class="mylayout-right">
				<DroneCard
					class="sticky"
					:drone="selectedDrone.drone"
					:lot="selectedDrone.lot"
					:model="selectedDrone.model"
					:owner="selectedDrone.customer"
					:firmware="selectedDrone.firmware"
					:connect="selectedDrone.connect" />
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import DroneCard from "@components/drone/DroneCard.vue"
import TicketForm from "@components/ticket/TicketForm.vue"
import axios from "axios"

export default {
	components : {
		DroneCard, TicketForm,
	} ,
	mixins : [PageMixin] ,
	page() {
		return {
			title : this.$t('page.title',{service : this.service.service_no})
		}
	},
	data() {
		return {
			loading : false ,
			selectedDrone : {} ,

			service : {} ,
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				this.$open({name : 'dashboard'})
				return
			}
			this.showPageLoading(true)
			axios.get("/api/tickets/init-from-service/"+serviceId).then((response) => {
				this.service = response.data.data.service
				this.selectedDrone = this.service.droneData

				const ticket = response.data.data.ticket
				const comment = response.data.data.comment
				if (!this.$notEmpty(comment.detail_rich)) {
					comment.detail_rich = ''
				}
				comment.detail_rich += "<p><br></p>"
				comment.detail_rich += "<p><span style='color: rgb(0, 71, 178);'>["+this.$t('ticket_create.comment.note')
				comment.detail_rich += " <a rel=\"noopener noreferrer\" target=\"_blank\" href=\""
				comment.detail_rich += window.location.origin+this.$router.resolve({name : 'service/view', params : {id : this.service.id}}).route.fullPath
				comment.detail_rich += "\">"+this.service.service_no+"</a> "
				comment.detail_rich += "]</span></p>"

				this.$refs.formRef.updateTicketForm(ticket,comment)
			}).catch((error) => {
				this.fetchError(error)
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		goBack() {
			this.$router.push({name:'service/view',params : {id : this.service.id}})
		} ,
		handleSubmit(formData) {
			formData.ticket.drone_id = this.selectedDrone.drone.id

			this.$confirm({
				title : this.$t('ticket_create.confirm.title') ,
				content : this.$t('ticket_create.confirm.message') ,
				maskClosable : true,
				onOk: () => {
					this.loading = true

					axios.post('/api/tickets/create-from-service/'+this.service.id,formData).then((response)=>{
						const ticket = response.data.data.ticket;

						this.$message.success(this.$t('ticket_create.success',{"ticket" : ticket.ticket_no}))
						this.$router.replace({name:'ticket/view',params: {id : ticket.id}})
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=> {
						this.loading = false
					})
				}
			});
		} ,
	}
}
</script>
<style lang="less" scoped>
.service-link-block	{
	font-size : 14px;
	display : block;
	margin-top : 20px;
}
</style>
